.topbar .top-navbar .navbar-header {
  line-height: 0px !important;
}

.form-control:focus {
  box-shadow: none;
  border-color: #ced4da;
}

.select2-container .select2-selection--multiple {
  min-height: 38px;
  border-color: #ced4da;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 1.5px 5px 0;
}

.text-error {
  color: red;
}

.zindex0 {
  z-index: 0;
}

/* LOADING */
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20,.4), rgba(0, 0, 0, .4));
  background: -webkit-radial-gradient(rgba(20, 20, 20,.4), rgba(0, 0, 0,.4));
}

/* ############## TAGS ############### */

div.ReactTags__selected {
  display: flex;
  align-items: self-start;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  min-height: 38px;
  color: #67757c;
  font-weight: 400;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

div.ReactTags__selected span.tag-wrapper {
  border: 1px solid #ddd;
  background: #eee;
  font-size: 12px;
  display: inline-block;
  border-radius: 2px;
  padding: 3px 24px 5px 12px;
  margin: 5px 0 0 4px;
  position: relative;
  bottom: 1px;
  flex-grow: 1;
  text-align: center;
}

div.ReactTags__selected span.tag-wrapper a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 3.5px;
}

div.ReactTags__selected div.ReactTags__tagInput {
  display: inline;
  flex-grow: 99;
  padding: 0 6px;
}

div.ReactTags__selected input.ReactTags__tagInputField {
  width: 100%;
  height: 36px;
  font-size: 1rem;
  line-height: 1.5;
  color: #67757c;
  border: none;
}

/* ############## FANZONE ############### */

.SortableList {
  position: relative;
  z-index: 0;
  background-color: #F3F3F3;
  border: 1px solid #EFEFEF;
  border-radius: 3px;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #E0E0E0;
  list-style: none;
  padding: 0;
}

.SortableItem {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background-color: #FFF;
  border-bottom: 1px solid #EFEFEF;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #333;
  font-weight: 500;
}

.SortableHelper {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 0.8);
  cursor: row-resize;
}

.card-fanzone-news {
  margin-bottom: 0px !important;
}

.img-fanzone-news {
  position: absolute;
  width: 83px !important;
}

.p-l-120 {
  position: relative;
  padding-left: 120px;
  font-weight: 500;
}

/* LICENSE */

.license-background {
  background: url(../images/background/license-background.jpg) no-repeat center center #fff;
  background-size: cover;
  width: 100%;
}

#app > .license-background {
  min-height: 100vh;
}

.page-wrapper > .license-background {
  position: absolute;
  top: 70px;
  bottom: 60px;
  overflow-y: scroll;
}

.license-background .col-free,
.license-background .col-gold {
  position: absolute;
  width: 45%;
  backdrop-filter: blur(10px);
  padding: 45px 58px;
}

.license-background .col-free {
  background-color: rgba(18, 22, 41, .7);
  right: 50%;
  top: 70px;
  bottom: 70px;
}

.license-background .col-gold {
  background-color: rgba(42, 56, 156, .32);
  left: 50%;
  top: 40px;
  bottom: 40px;
}

.license-background .free,
.license-background .gold {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (max-width: 992px) {
  .license-background .col-free,
  .license-background .col-gold {
    position: initial;
    display: block;
    width: 100%;
  }
}

.license-background .body {
  overflow-y: auto;
  flex: 1 1 auto;
  margin-bottom: 30px;
}

.license-background .title {
  font-size: 22px;
}

.license-background .title-big {
  border-bottom: solid #717ba8 1px;
  font-size: 42px;
  font-weight: 600;
}

.license-background .title-big-original {
  font-size: 18px;
  font-weight: 400;
  text-decoration: line-through;
  margin-left: 5px;
}


.license-background .col-free .title-big {
  border-bottom: solid rgba(113, 123, 168, .7) 1px;
}

.license-background .title-big sup {
  font-size: 17px;
  font-weight: 500;
  margin-right: 9px;
}

.license-background .title-big-original sup {
  font-size: 17px;
  font-weight: 500;
  margin-right: 9px;
}

.license-background p {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 500;
}

.license-background .highlighted {
  margin-top: -24px;
  color: #eabd8c;
}

.license-background .highlighted-free {
  color: #616fe3;
}

.license-background p.gray-color {
  font-weight: 300;
  opacity: .75;
  font-size: 13px;
}

.license-background .slider-row {
  position: relative;
}

.license-background .slider-col {
  position: absolute;
}

.license-background .slider {
  margin: 9px auto 0;
  width: 76%;
  background-color: rgba(63, 73, 140, 0.42);
  height: 7px;
}

.license-background .fans-radio[type="radio"] {
  display:none;
}

.license-background .fans-radio[type="radio"] + label {
  position: relative;
  z-index: 100;
}

.license-background .slider-point,
.license-background .fans-radio[type="radio"] + label span {
  display:inline-block;
  width:27px;
  height:27px;
  margin:-1px 4px 0 0;
  vertical-align:middle;
}

.license-background .slider-point-gold {
  background:url(../images/license-radios.png) right top no-repeat;
}

.license-background .fans-radio-gold[type="radio"] + label span {
  cursor:pointer;
  background:url(../images/license-radios.png) right top no-repeat;
}

.license-background .fans-radio-gold[type="radio"]:checked + label span {
  background:url(../images/license-radios.png) left top no-repeat;
}

.license-background .slider-point-free {
  background:url(../images/license-radios.png) right bottom no-repeat;
}

.license-background .fans-radio-free[type="radio"] + label span {
  cursor:pointer;
  background:url(../images/license-radios.png) right bottom no-repeat;
}

.license-background .fans-radio-free[type="radio"]:checked + label span {
  background:url(../images/license-radios.png) left bottom no-repeat;
}

.license-background .hover-col {
  top: -23px;
  right: 0;
  left: 0;
}

.license-background .hover-free,
.license-background .hover-gold {
  display: block;
  padding-top: 30px;
}

.license-background .hover-free span,
.license-background .hover-gold span {
  position: relative;
  color: white;
  padding: 15px 10px;
  border-radius: 50%;
}

.license-background .hover-free span {
  background-color: #616fe3;
}
.license-background .hover-gold span {
  color: #333589;
  background-image: linear-gradient(
          129deg,
          #de8a2a -46%,
          #f5e1b6 21%,
          #f6e6bf 28%,
          #f5e0b8 36%,
          #e27e39 93%
  );
}

.license-background .hover-free span::before,
.license-background .hover-gold span::before {
  content: '';
  width: 10px;
  height: 10px;
  position: absolute;
  top: -3px;
  left: 19px;
  transform: rotate(45deg);
}

.license-background .hover-free span::before {
  background-color: #616fe3;
}

.license-background .hover-gold span::before {
  background-color: #f5e5be;
  z-index: -1;
}

.license-background label {
  display: block;
}

.license-background p,
.license-background label {
  color: #fff;
}

.license-background .subsection {
  margin-top: 50px;
}

.license-background .subsection-title {
  margin-bottom: 8px;
  text-align: left;
  font-weight: bold;
}

.license-background .ReactTags__selected {
  border: solid 1px #616fe3;
  border-radius: 8px;
}

.license-background .ReactTags__tag {
  margin: 3px !important;
  background-color: #616fe3 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 8px !important;
  font-weight: 600 !important;
}

.license-background .ReactTags__tagInputField {
  caret-color: #5265ff !important;
  font-weight: 500 !important;
  color: #fff !important;
  background: none;
}

.license-background .ReactTags__remove {
  color: #fff !important;
}

.license-background .ReactTags__tagInputField::placeholder {
  color: #fff;
}

.license-background .control-label {
  color: #fff;
  font-weight: 500;
}

.license-background .form-control {
  border-radius: 8px;
  border: solid 1px #616fe3;
  background: none;
  caret-color: #5265ff;
  color: #fff;
  font-weight: 500;
}

.license-background .form-control:focus {
  background-color: #262e71;
}

.license-background .form-control[type=number]::-webkit-inner-spin-button,
.license-background .form-control[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.license-background .free-button,
.license-background .gold-button {
  display: block;
  margin: 0 auto;
  max-width: 90%;
}

.license-background .free-button {
  background: none;
  width: 327px;
  height: 62px;
  border: solid 1px #616fe3;
  border-radius: 8px;

  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #616fe3;
}

.license-background .free-button:hover {
  background-color: #616fe3;
  color: #fff;
  cursor: pointer;
}

.license-background .gold-button {
  background-image: linear-gradient(
          100deg,
          #de8a2a -53%,
          #f5e1b6 27%,
          #f6e6bf 35%,
          #f5e0b8 46%,
          #ecc495 76%,
          #d06e2a 113%
  );
  width: 327px;
  height: 62px;
  border-radius: 8px;
  border: none;

  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-shadow: 2px 2px 0 rgba(255, 255, 255, 0.5);
  color: #ab5512;
}

.license-background .gold-button:hover {
  cursor: pointer;
}

/* END LICENSE */

/* PLAYERS */

.player-avatar {
  max-height: 40px;
  max-width: 40px;
  border-radius: 50%;
}

/* END PLAYERS */

.graph-buttons {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 20px;
}