.dropzone {
  width: 100%;
  height: 300px;
  font-weight: 400;
  object-fit: cover;
  object-position: center center;
  border: 1.5px dashed #ced4da;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.3s ease-in;
}

.dropzone:hover {
  cursor: pointer;
  border-color: #ced4da;
  background-color: #e9ecef;
  color: #495057;
  border-color: #495057;
}

.dropzone.accept {
  border-color: #66dd66;
}

.dropzone.reject {
  border-color: #dd6666;
}

.dropzone .upload-icon {
  mask: url("../images/icon/upload-cloud.svg");
  background-color: #ced4da;
  height: 60px;
  width: 60px;
  transition: all 0.3s ease-in;
}

.dropzone .message {
  margin-top: 10px;
}

.dropzone:hover .upload-icon {
  background-color: #495057;
}

.dropzone.accept .upload-icon {
  background-color: #66dd66;
}

.dropzone.reject .upload-icon {
  background-color: #dd6666;
}

.dropzone aside {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropzone .fa.fa-times {
  position: absolute;
  right: 12px;
  top: 10px;
  text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000, 0 0 2px black;
  color: #ced4da;
}

.dropzone .fa.fa-times:hover {
  color: white;
}

.dropzone aside img {
  object-fit: cover;
  object-position: center center;
  width: 100%;
  height: 100%;
}

.dropzone aside .list {
  overflow: scroll;
}

.dropzone aside .list ul {
  margin: 0;
  padding: 30px 30px 30px 46px;
}

.dropzone aside .list ul li {
  margin-top: 10px;
}

.dropzone aside .list ul li:first-child {
  margin-top: 0;
}
